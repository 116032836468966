import {combineReducers} from 'redux';

import userinfoState from './reducers/userinfoReducer'
import postalCodeState from './reducers/postalCodeReducer'
import hazardLayerState from './reducers/hazardLayerReducer'
import mapState from './reducers/mapReducer'
import mapControlState from './reducers/mapControlReducer'
import markers from './reducers/markers';
import loginState from './reducers/loginReducer';

const appReducer = combineReducers({
    userinfoState: userinfoState,
    postalCodeState: postalCodeState,
    hazardLayerState: hazardLayerState,
    mapState: mapState,
    mapControlState: mapControlState,
    markers: markers,
    loginState: loginState
})

const combinedReducer = (state, action) => {
    if (action.type === "RESET_STATE") {
        // 全てのstateをリセットする。再ログインが必須になる。
        state = undefined;
    } else if (action.type === "RESET_USER_INPUT") {

        // ログイン情報以外をリセットする。
				// このシステムでのログイン情報とは、単に開催場所を意味するので基本的にリセット不要。
        
        state.userinfoState = undefined;
        state.postalCodeState = undefined;
        state.hazardLayerState = undefined;
        state.mapState = undefined;
        state.mapControlState = undefined;
        state.markers = undefined;
    } 
    
    
    return appReducer(state, action);
}

export default combinedReducer