import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Titlelogo from "../assets/img/logo.png";
import IconFormBoy from "../assets/img/icon_form_boy.png";
import IconFormGirl from "../assets/img/icon_form_girl.png";
import { updateUserInfo, updateUserId, clearState } from "../store/actions";
import { Loader } from '../components';
import { useCookies } from "react-cookie";

const Home = props => {
  const [name, setName] = useState("");
  const [age, setAge] = useState(undefined);
  const [sex, setSex] = useState(undefined);
  const [hasfam, setHasfam] = useState(undefined);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["state"]);
  const submit = () => {
    if (name !== "" && (age !== undefined && age !== "null") && sex !== undefined && (hasfam !== undefined && hasfam !== "null")) {
      dispatch(updateUserInfo({
        name: name, age: age, sex: sex, hasfam: hasfam
      }))
      const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const N = 16;
      const userId = Array.from(Array(N)).map(() => S[Math.floor(Math.random() * S.length)]).join('');
      dispatch(updateUserId(userId));
      delete require.cache[
        require.resolve('../assets/js/loading')
      ];
      props.history.push("/start");
    } else {
      window.alert("入力できていない項目があるよ");
    }
    return
  }
  const logout = () => {
    removeCookie("state");
    dispatch(clearState());
    props.history.push("/Login");
  }
  require('../assets/js/loading');
  return (
    <div>
      <Loader />
      <div className="l-container is-map">
        <div className="l-contents">
          <h1 className="p-logo">
            <img src={Titlelogo} alt="マイマップ" />
          </h1>
          <dl className="p-dl-list is-form is-first">
            <dt>ねんれい</dt>
            <dd>
              <p className="f-select f-w-xs">
                <select
                  name="sample-1"
                  value={age}
                  onChange={e => setAge(e.target.value)}
                >
                  <option value="null" defaultValue>
                    えらぶ
                    </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                </select>
              </p>
                さい
                {/*					<span class="f-error">※この項目は必須項目となります。</span>*/}
            </dd>
          </dl>
          <dl className="p-dl-list is-form">
            <dt>せいべつ</dt>
            <dd>
              <div className="f-radio">

                <p
                  className="f-radio-inner"
                  value={sex}
                  onChange={e => setSex(e.target.value)}
                >
                  <input
                    type="radio"
                    id="radio02-01"
                    name="demo01"
                    value="man"
                  />
                  <label htmlFor="radio02-01">
                    <img src={IconFormBoy} className="f-gender" alt="" />
                  </label>
                  <input
                    type="radio"
                    id="radio02-02"
                    name="demo01"
                    value="woman"
                  />
                  <label htmlFor="radio02-02">
                    <img src={IconFormGirl} className="f-gender" alt="" />
                  </label>
                </p>
              </div>
            </dd>
          </dl>
          <dl className="p-dl-list is-form">
            <dt>何人かぞく？</dt>
            <dd>
              <p className="f-select f-w-xs">
                <select
                  name="sample-1"
                  value={hasfam}
                  onChange={e => setHasfam(e.target.value)}
                >
                  <option value="null" defaultValue>
                    えらぶ
                    </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </p>
                人
              </dd>
          </dl>
          <dl className="p-dl-list is-form">
            <dt>ニックネーム</dt>
            <dd>
              <input
                type="text"
                className="f-w-lg"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </dd>
          </dl>
          <ul className="p-submit">
            <button
              className="c-btn c-btn-default c-btn-lg"
              type="button"
              name="runButton"
              value="aaa"
              onClick={() => submit()}
            >
              スタート
              </button>
            {/* <li><button class="c-btn c-btn-green c-btn-lg">スタート</button></li> */}
          </ul>

        </div>
      </div>
      {/* 全体画面 */}

      <div className="p-logout">
        <a onClick={() => logout()} className="p-logout-btn">
          <i className="__icon"></i><span>ログアウト</span>
        </a>
      </div>
      
      
      {/* フッター */}
      <div className="p-footer-stage">
        <div className="p-footer-wrap" data-order="left">
          <span className="p-footer-item p-footer-anime" />
        </div>
      </div>
      {/* フッター */}
    </div>
  );
}

// Enterキー無効化
// TODO depricatedの対応
document.onkeypress = function(e) {
  if (e.key === 'Enter') {
    return false;
  }
};

export default Home;
