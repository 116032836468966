import {SET_MAP} from '../actions'

const initialState = {
    mapObj: null
  };
  
  const mapState = (state = initialState, action) => {
    switch (action.type) {
      case SET_MAP:
        let newState = { ...state };
        newState.mapObj = action.payload;
        return newState;
      default:
        return state;
    }
  };
  
  export default mapState;
  