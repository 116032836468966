import React from "react";
import { useDispatch, useSelector } from "react-redux";
import YaneIcon from "../assets/img/icon_yane.png";
import { CombinedMap, Loader, StepBar } from "../components";
import { updateMarker } from "../store/actions";

const LoungeSection = props => {
  const markers = useSelector(state => state.markers);
  const userinfoState = useSelector(state => state.userinfoState);
  const mapState = useSelector(state => state.mapState);
  const dispatch = useDispatch();
  const onAddMarker = () => {
    dispatch(updateMarker("lounge", "push", mapState.mapObj.current.leafletElement.getCenter(), null));
    return
  }
  const jumpTo = pageName => {
    delete require.cache[require.resolve("../assets/js/loading")];
    props.history.push(pageName);
    return
  };
  if (markers.home === null) {
    jumpTo("/");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-wrap">
          <main className="l-main">
            <CombinedMap
              className="p-gmap"
              editType="lounge"
            />
          </main>
          <div className="l-side">
            <div className="l-side-inner">
              <section className="p-theme">
                <p className="p-theme-txt">
                  {userinfoState.infolist.age >= 9
                    ? "すこしつかれてきたよ…ねむたくもなってきたなぁ…屋根のある休憩場所はどこかなぁ"
                    : "すこしつかれてきたよ…ねむたくもなってきたなぁ…おやすみできる屋根のあるところはどこかなぁ"
                  }
                </p>
              </section>
              <section
                className="p-icon-area"
                onClick={() => onAddMarker()}
              >
                <button>
                  <img src={YaneIcon} alt="" />
                </button>
              </section>
              <ul className="p-btn-list">
                <li>
                  <button
                    className="c-btn c-btn-yellow c-btn-xl"
                    onClick={() => jumpTo("/hospital")}
                  >
                    つぎへ
                </button></li>
                <li><button
                  className="c-btn-back"
                  onClick={() => jumpTo("/water")}
                >
                  <span>もどる</span>
                </button></li>
              </ul>
              <section className="p-btn-end">
                <ul className="p-btn-list">
                  <li>
                    <button
                      onClick={() => jumpTo("/confirm")}
                      className="c-btn c-btn-red c-btn-xl"
                    >
                      完成確認
                    </button>
                  </li>
                </ul>
              </section>
            </div>
          </div>
          <StepBar step="lounge" history={props.history} />
        </div>
      </div>
    );
  }
}

export default LoungeSection;

