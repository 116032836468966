import { UPDATE_POSTAL_CODE } from "../actions";
import { UPDATE_MAP_CENTER } from "../actions";

const initialState = {
  postalCode: "",
  mapCenter: null
};

const postalCodeState = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_POSTAL_CODE:
      let newState = { ...state };
      newState.postalCode = action.payload;
      return newState;
    case UPDATE_MAP_CENTER:
      let nextState = { ...state };
      nextState.mapCenter = action.payload;
      return nextState;
    default:
      return state;
  }
};

export default postalCodeState;
