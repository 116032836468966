import React, {useState} from 'react';
import _env from '../config/env';
import {useDispatch, useSelector} from "react-redux";
import img_kouzui from "../assets/img/img_kouzui.svg";
import img_takashio from "../assets/img/img_takashio.svg";
import img_tsunami from "../assets/img/img_tsunami.svg";
import img_dosekiryu from "../assets/img/img_dosekiryu.svg";
import img_keisha from "../assets/img/img_keisha.svg";
import img_jisuberi from "../assets/img/img_jisuberi.svg";
import {toggleHazardModal, updateHazardLayer} from "../store/actions";

const HazardModal = props => {
  
  const dispatch = useDispatch();
  
  const changeLayer = layer => {
    dispatch(updateHazardLayer(layer));
  }

  const showModal = (show) => {
    dispatch(toggleHazardModal(show));
  }
  
  return (
    <div className={"l-hazard js-active"}>
      <div className="l-hazard__inner">
        <div className="p-hazard__catch">
          <h2 className="__ttl">ハザードを選んでね！</h2>
          <p className="__btn">
            <button type={"button"} onClick={() => showModal(false)} className="c-btn c-btn-default c-btn-xs">とじる</button>
          </p>
        </div>
        <ul className="p-hazard__list">
          <li>
            <input name="test" id="select1" value="1" checked="" type="radio"
                   onClick={() =>
                     changeLayer("kouzui")
                   }
            />
            <label for="select1">
              <dl>
                <dt><img src={img_kouzui} alt="" /></dt>
                <dd>洪水</dd>
              </dl>
            </label>
          </li>
          <li>
            <input name="test" id="select2" value="2" type="radio"
                   onClick={() =>
                     changeLayer("takashio")
                   } />
            <label for="select2">
              <dl>
                <dt><img src={img_takashio} alt="" /></dt>
                <dd>高潮</dd>
              </dl>
            </label>
          </li>
          <li>
            <input name="test" id="select3" value="3" type="radio"
                   onClick={() =>
                     changeLayer("tsunami")
                   } />
            <label for="select3">
              <dl>
                <dt><img src={img_tsunami} alt="" /></dt>
                <dd>津波</dd>
              </dl>
            </label>
          </li>
          <li>
            <input name="test" id="select4" value="4" type="radio"
                   onClick={() =>
                     changeLayer("dosekiryu")
                   } />
            <label for="select4">
              <dl>
                <dt><img src={img_dosekiryu} alt="" /></dt>
                <dd>土石流</dd>
              </dl>
            </label>
          </li>
          <li>
            <input name="test" id="select5" value="5" type="radio"
                   onClick={() =>
                     changeLayer("kyukeisha")
                   } />
            <label for="select5">
              <dl>
                <dt><img src={img_keisha} alt="" /></dt>
                <dd>急傾斜地</dd>
              </dl>
            </label>
          </li>
          <li>
            <input name="test" id="select6" value="6" type="radio"
                   onClick={() =>
                     changeLayer("jisuberi")
                   } />
            <label for="select6">
              <dl>
                <dt><img src={img_jisuberi} alt="" /></dt>
                <dd>地すべり</dd>
              </dl>
            </label>
          </li>
          <li>
            <input name="test" id="select7" value="7" type="radio"
                   onClick={() =>
                     changeLayer("nothing")
                   } />
            <label for="select7">
              <dl>
                <dd>表示しない</dd>
              </dl>
            </label>
          </li>
        </ul>
      </div>
    </div>
    );
}

export default HazardModal;