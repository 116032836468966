import { 
  SET_LOGIN_STATE,
  SET_USER_STATE,
  CLEAR_STATE
} from '../actions';

const initialState = {
  isLogin: false,
  userInfo: {
    email: null,
    token: null,
  },
};

const loginState = (state = initialState, action) => {
  switch(action.type){
    case SET_LOGIN_STATE:
      let newloginState = {...state};
      newloginState.isLogin = action.payload;
      return newloginState;
    case SET_USER_STATE:
      let newuserState = {...state};
      newuserState.userInfo = action.payload;
      return newuserState;
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default loginState;