import React, {useEffect} from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

// pages
import Home from "../views/Home";
import PasswordSection from "../views/PasswordSection";
import PrintSection from "../views/PrintSection";
import StartSection from "../views/StartSection";
import HomeSection from "../views/HomeSection";
import RelativesSection from '../views/RelativesSection'
import ShelterSection from "../views/ShelterSection";
import HazardSection from "../views/HazardSection";
import ToiletSection from "../views/ToiletSection";
import WaterSection from "../views/WaterSection";
import LoungeSection from "../views/LoungeSection";
import HospitalSection from "../views/HospitalSection";
import CarSection from "../views/CarSection";
import ConfirmSection from '../views/ConfirmSection'
import EndSection from "../views/EndSection";
import LoginSection from "../views/LoginSection";

import _env from '../config/env';
import { useCookies } from "react-cookie";
import {setLoginState, setUserState} from "../store/actions";

const AppContainer = props => {
  
  const loginState = useSelector(state => state.loginState);
  const [cookies, setCookie, removeCookie] = useCookies(["state"]);
  
  const dispatch = useDispatch();
  
  // F5キーによる画面リフレッシュ時のcookieからのログイン処理
  useEffect(() => {

    console.log("cookies.state")
    console.log(cookies.state)
    
    if (cookies.state && ( ! loginState.isLogin)) {
      dispatch(setUserState({
        email: cookies.state.userInfo.email,
        token: cookies.state.userInfo.token
      }));

      dispatch(setLoginState(true));
    }
    
  }, [])
  
  // できれば true -> cookies.state.isLogin に変更
  const isLogin = () => {
    return cookies.state ? true : loginState.isLogin;
  }
  
  return (
    <div className="App">
      <Router>
        
        {/* ログイン不要の画面2種類 */}
        <Route path="/password" component={PasswordSection} />
        <Route path="/print" component={PrintSection} />
        
        {_env.publishMode === "publish" ?
          <>
            <Route path="/Login" component={LoginSection}>
              {isLogin() ? <Redirect to="/" /> : null}
            </Route>
            
            <Route exact path="/" component={Home}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            
            <Route path="/start" component={StartSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/home" component={HomeSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/relatives" component={RelativesSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/shelter" component={ShelterSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/hazard" component={HazardSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/toilet" component={ToiletSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route
              path="/water"
              component={WaterSection}
            >
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/lounge" component={LoungeSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/hospital" component={HospitalSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/car" component={CarSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/confirm" component={ConfirmSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
            <Route path="/end" component={EndSection}>
              {isLogin() ? null : <Redirect to="/Login" />}
            </Route>
          </> : null}

      </Router>
    </div>
  );
}

export default AppContainer;
