import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CombinedMap,
  Loader,
  StepBar
} from "../components";
import HomeIcon from "../assets/img/icon_home.png";
import {
  updateMarker
} from "../store/actions";

const HomeSection = props => {
  const markers = useSelector(state => state.markers);
  const userinfoState = useSelector(state => state.userinfoState);
  const mapState = useSelector(state => state.mapState);
  const postalCodeState = useSelector(state => state.postalCodeState);
  const dispatch = useDispatch();
  const onAddMarker = () => {
    dispatch(updateMarker("home", "replace", mapState.mapObj.current.leafletElement.getCenter(), null));
    return
  }
  const jumpTo = pageName => {
    if (pageName === "/start" || markers.home !== null) {
      delete require.cache[require.resolve("../assets/js/loading")];
      props.history.push(pageName);
    } else {
      window.alert("ホームマーカの設置が終了していません");
    }
    return
  }
  if (postalCodeState.mapCenter === null) {
    delete require.cache[require.resolve("../assets/js/loading")];
    props.history.push("/");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-wrap">
          <main className="l-main">
            <CombinedMap
              className="p-gmap"
              editType="home"
            />
          </main>
          <div className="l-side">
            <div className="l-side-inner">
              <section className="p-theme">
                <p className="p-theme-txt">
                  {userinfoState.infolist.age >= 9
                    ? "自分の家はどこかな?お家マークをおいてみよう！！"
                    : "じぶんのいえはどこかな?おうちマークをおいてみよう！！"
                  }
                </p>
              </section>
              <section
                className="p-icon-area"
              >
                <button>
                  <img
                    src={HomeIcon}
                    alt="home_icon"
                    onClick={() => onAddMarker()}
                  />
                </button>
              </section>
              <ul className="p-btn-list">
                <li>
                  <button
                    className="c-btn c-btn-yellow c-btn-xl"
                    onClick={() => jumpTo("/relatives")}
                  >
                    つぎへ
                  </button>
                </li>
                <li>
                  <button className="c-btn-back" onClick={() => jumpTo("/start")}>
                    <span>もどる</span>
                  </button>
                </li>
              </ul>
              <section className="p-btn-end">
                <ul className="p-btn-list">
                  <li>
                    <button
                      onClick={() => jumpTo("/confirm")}
                      className="c-btn c-btn-red c-btn-xl"
                    >
                      完成確認
                    </button>
                  </li>
                </ul>
              </section>
              <StepBar step="home" history={props.history} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeSection;
