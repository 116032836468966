import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Map,
  Marker,
  TileLayer,
  ZoomControl,
  ScaleControl,
  Popup
} from "react-leaflet";
import L from "leaflet";
import HomeIcon from "../assets/img/icon_home.png";
import ShelterIcon from "../assets/img/icon_hinanjo.png";
import ToyotaIcon from "../assets/img/icon_toyota.png";
import SchoolIcon from "../assets/img/icon_school.png";
import {
  setMap,
  updateMapControl,
  updateMarker
} from "../store/actions";
import _env from '../config/env';

const homeIcon = new L.Icon({
  iconUrl: HomeIcon,
  iconRetinaUrl: HomeIcon,
  iconAnchor: [30, 60],
  iconSize: [60, 60],
  popupAnchor: [0, -60]
});

const shelterIcon = new L.Icon({
  iconUrl: ShelterIcon,
  iconRetinaUrl: ShelterIcon,
  iconAnchor: [20, 40],
  iconSize: [40, 40],
  popupAnchor: [0, -40]
});

const toyotaIcon = new L.Icon({
  iconUrl: ToyotaIcon,
  iconRetinaUrl: ToyotaIcon,
  iconAnchor: [30, 60],
  iconSize: [60, 60],
  popupAnchor: [0, -60]
});

const schoolIcon = new L.Icon({
  iconUrl: SchoolIcon,
  iconRetinaUrl: SchoolIcon,
  iconAnchor: [30, 60],
  iconSize: [60, 60],
  popupAnchor: [0, -60]
});

const CombinedMap = props => {
  const markers = useSelector(state => state.markers);
  const mapControlState = useSelector(state => state.mapControlState);
  const postalCodeState = useSelector(state => state.postalCodeState);
  const hazardLayerState = useSelector(state => state.hazardLayerState);
  const dispatch = useDispatch();
  const mapRef = useRef();
  const onHomeDragEnd = e => {
    const coords = e.target._latlng;
    const zoom = e.target._map._zoom;
    dispatch(updateMarker("home", "replace", coords, null));
    dispatch(updateMapControl({ zoom: zoom, center: coords }));
  }
  const onMarkerDragEnd = (e, idx) => {
    const coord = e.target._latlng;
    dispatch(updateMarker(props.editType, "edit", coord, idx));
  }
  const saveMapControl = e => {
    const zoom = e.target.getZoom();
    const center = e.target.getCenter();
    dispatch(updateMapControl({ zoom: zoom, center: center }));
  }
  const removeMarker = idx => {
    if (window.confirm("このマーカを削除しますか？")) {
      const closeButtonElement = document.getElementsByClassName(
        "leaflet-popup-close-button"
      );
      dispatch(updateMarker(props.editType, "remove", null, idx));
      closeButtonElement[0].click();
    }
    else {
      window.alert("キャンセルされました");
    }
  };
  useEffect(() => {
    dispatch(setMap(mapRef));
  }, [mapRef])
  return (
    <>
      <div className="App">
        <Map
          ref={mapRef}
          minZoom={5}
          zoom={mapControlState.zoom}
          maxZoom={18}
          center={
            mapControlState.center ||
            markers.home ||
            postalCodeState.mapCenter
          }
          zoomControl={false}
          onZoomEnd={e => saveMapControl(e)}
          onDragend={e => saveMapControl(e)}
        >
          {/* OSM */}
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          
          {/* 洪水レイヤ */}
          {hazardLayerState.hazardLayer === "kouzui" ? (
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/01_flood_l2_shinsuishin_kuni_data/{z}/{x}/{y}.png"
              maxZoom={18}
              minZoom={6}
            />
          ) : null}

          {/* 高潮 */}
          {hazardLayerState.hazardLayer === "takashio" ? (
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/03_hightide_l2_shinsuishin_data/{z}/{x}/{y}.png"
              maxZoom={18}
              minZoom={6}
            />
          ) : null}

          {/* tsunami */}
          {hazardLayerState.hazardLayer === "tsunami" ? (
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/04_tsunami_newlegend_data/{z}/{x}/{y}.png"
              maxZoom={18}
              minZoom={6}
            />
          ) : null}

          {/* dosekiryu */}
          {hazardLayerState.hazardLayer === "dosekiryu" ? (
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/{z}/{x}/{y}.png"
              maxZoom={18}
              minZoom={6}
            />
          ) : null}

          {/* kyukeisha */}
          {hazardLayerState.hazardLayer === "kyukeisha" ? (
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/{z}/{x}/{y}.png"
              maxZoom={18}
              minZoom={6}
            />
          ) : null}

          {/* jisuberi */}
          {hazardLayerState.hazardLayer === "jisuberi" ? (
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/{z}/{x}/{y}.png"
              maxZoom={18}
              minZoom={6}
            />
          ) : null}
          
          
          
          
          
          
          
          
          
          {/* 避難所のマーカ */}
          {markers.shelterVisible && 
            markers.shelter.map((coord, idx) => 
              <Marker position={coord} icon={shelterIcon} key={idx.toString()} />
            )}

          {/* 販売店のマーカ */}
          {props.editType === "confirm" || props.editType === "print"
            ? markers.shop.map(
              (marker, idx) => (
                <Marker
                  key={`shopMarker-${idx}`}
                  position={[marker.lat, marker.lon]}
                  icon={toyotaIcon}
                >
                  <Popup>{marker.place_name}</Popup>
                </Marker>
              )
            )
            : null}

          {/* 小学校のマーカ */}
          {markers.school.map(
            (marker, idx) => (
              <Marker
                key={`schoolMarker-${idx}`}
                position={[marker.lat, marker.lon]}
                icon={schoolIcon}
              >
                <Popup>{marker.place_name}</Popup>
              </Marker>
            )
          )}

          {/* 家のマーカ */}
          {markers.home !== null ? (
            <Marker
              draggable={props.editType === "home"}
              position={
                markers.home ||
                postalCodeState.mapCenter
              }
              icon={homeIcon}
              key={"homeMarker"}
              onDragend={e => onHomeDragEnd(e)}
            />
          ) : null}

          {_env.markerList.map(marker => {
            const icon = new L.Icon({
              iconUrl: marker.iconUrl,
              iconRetinaUrl: marker.iconRetinaUrl,
              iconAnchor: marker.iconAnchor,
              iconSize: marker.iconSize,
              popupAnchor: marker.popupAnchor
            });
            return (
              <div key={marker.category}>
                {
                  markers[marker.category].map((position, idx) => (
                    <Marker
                      draggable={props.editType === marker.category}
                      icon={icon}
                      key={`${marker.category}Marker-${idx}`}
                      position={position}
                      onDragend={e => onMarkerDragEnd(e, idx)}
                    >
                      {props.editType === marker.category ? (
                        <Popup>
                          <button
                            className="c-btn c-btn-default c-btn-lg"
                            onClick={() => removeMarker(idx)}
                          >
                            このマーカを削除する
                    </button>
                        </Popup>
                      ) : null}
                    </Marker>
                  ))
                }
              </div>
            );
          })}
          <ScaleControl position="bottomright" />
          <ZoomControl position="bottomright" />
        </Map>
      </div>
    </>
  );
}

export default CombinedMap;