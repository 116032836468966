import React from 'react';

const Loader = props => {
    return (
        <div id="l-loader-bg">
            <div id="l-loader">
                <div className="p-spinner">
                    <div className="p-double-bounce1"></div>
                    <div className="p-double-bounce2"></div>
                </div>
                <p>Loading...</p>
            </div>
        </div>
    );
};

export default Loader;