import $ from 'jquery';
// window.$ = $
$(function() {
  var h = $(window).height();
  $('#l-wrap').css('display','none');
  $('#l-loader-bg ,#l-loader').height(h).css('display','block');
});
  
$(window).on(function () { //全ての読み込みが完了したら実行
  $('#l-loader-bg').delay(900).fadeOut(800);
  $('#l-loader').delay(600).fadeOut(300);
  $('#l-wrap').css('display', 'block');
});

function stopload(){
  $('#l-wrap').css('display','block');
  $('#l-loader-bg').delay(900).fadeOut(800);
  $('#l-loader').delay(600).fadeOut(300);
}

//10秒たったら強制的にロード画面を非表示
$(function(){
  setTimeout(stopload,10);
});