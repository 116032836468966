import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePostalCode,
  updateMapCenter,
  updateMarker
} from "../store/actions";
import Axios from "axios";
import { Loader } from '../components';
import _env from "../config/env";

const StartSection = props => {
  const loginState = useSelector(state => state.loginState);
  const [postalCode, setPostalCode] = useState("")
  const userinfoState = useSelector(state => state.userinfoState);
  const dispatch = useDispatch();
  const jumpTo = pageName => {
    delete require.cache[
      require.resolve("../assets/js/loading")
    ];
    props.history.push(pageName);
    return
  }

  const onPageChange = () => {
    dispatch(updatePostalCode(postalCode));
    Axios.get(`https://maps.google.com/maps/api/geocode/json`, {
      params: {
        address: postalCode,
        key: _env.googleApiKey
      }
    })
      .then(res => {
        let latlng = res.data.results[0].geometry.location;
        dispatch(updateMapCenter(latlng));
        delete require.cache[require.resolve('../assets/js/loading')]
        jumpTo("/home");
      })
      .catch(err => {
        window.alert("郵便番号がまちがっていないかな？")
      });
    return
  }
  const fetchMarker = async () => {
    await Axios.get(
      `${_env.fastAPI}/markers`,
      {
        params: {},
        headers: {
          Authorization: `Bearer ${loginState.userInfo.token}`,
        }
      }).then(res => {
      dispatch(updateMarker("shelter", "replace", res.data, null));
    });
    return
  };
  const fetchDistributionMarker = async () => {
    await Axios.get(`${_env.fastAPI}/distribution_markers`,
      {
        params: {},
        headers: {
          Authorization: `Bearer ${loginState.userInfo.token}`,
        }
      }).then(res => {
      dispatch(updateMarker("shop", "replace", res.data, null));
    });
    return
  };
  const fetchSchoolMarker = async () => {
    await Axios.get(`${_env.fastAPI}/school_markers`,
      {
        params: {},
        headers: {
          Authorization: `Bearer ${loginState.userInfo.token}`,
        }
      }).then(res => {
      dispatch(updateMarker("school", "replace", res.data, null));
    });
    return
  };
  useEffect(() => {
    fetchMarker();
    fetchDistributionMarker();
    fetchSchoolMarker();
  }, [])
  if (userinfoState.infolist.sex === "b") {
    jumpTo("/");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-container is-map">
          <div className="l-contents">
            <div className="u-txt-c">
              <h1 className="p-zipcord-ttl">
                {userinfoState.infolist.age >= 9
                  ? "自分だけの地図を作るよ"
                  : "じぶんだけのちずをつくるよ"}
                <br />
                まずは
              </h1>
              <p className="p-midashi">
                {userinfoState.infolist.age >= 9
                  ? "郵便番号を教えてね"
                  : "ゆうびんばんごうをおしえてね"
                }
              </p>
              <p className="p-hosoku">ハイフンはいらないよ。</p>
              <p className="p-zipcord">
                <input
                  type="text"
                  placeholder="〒"
                  className="f-w-md"
                  value={postalCode}
                  onChange={e => setPostalCode(e.target.value)}
                />
              </p>
              <ul className="p-submit">
                <li>
                  <button
                    className="c-btn c-btn-default c-btn-lg"
                    onClick={() => onPageChange()}
                  >
                    つぎへ
                  </button>
                </li>
                <li>
                  <button
                    className="c-btn-back"
                    onClick={() => jumpTo("/")}
                  >
                    <span>もどる</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="p-footer-stage">
          <div className="p-footer-wrap" data-order="left">
            <span className="p-footer-item p-footer-anime" />
          </div>
        </div>
      </div>
    );
  }
}

// Enterキー無効化
// TODO depricatedの対応
document.onkeypress = function(e) {
  if (e.key === 'Enter') {
    return false;
  }
};

export default StartSection;
