export const CLEAR_STATE = "CLEAR_STATE";
export const clearState = () => {
    return {
        type: CLEAR_STATE
    }
}

// Homeページで使う
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const updateUserInfo = obj => {
  return {
    type: UPDATE_USER_INFO,
    payload: obj
  };
};

export const UPDATE_USER_ID = "UPDATE_USER_ID";
export const updateUserId = id => {
  return {
    type: UPDATE_USER_ID,
    payload: id
  }
}

// Startページで使う
export const UPDATE_POSTAL_CODE = "POSTAL_CODE";
export const updatePostalCode = postalCode => {
  return {
    type: UPDATE_POSTAL_CODE,
    payload: postalCode
  };
};

export const UPDATE_MAP_CENTER = "UPDATE_MAP_CENTER";
export const updateMapCenter = coord => {
  return {
    type: UPDATE_MAP_CENTER,
    payload: coord
  };
};

// Hazardページで使う
export const UPDATE_HAZARD_VISIBLE = "UPDATE_HAZARD_VISIBLE";
export const updateHazardVisible = isVisible => {
  return {
    type: UPDATE_HAZARD_VISIBLE,
    payload: isVisible
  };
};

export const UPDATE_HAZARD_LAYER = "UPDATE_HAZARD_LAYER";
export const updateHazardLayer = layer => {
  return {
    type: UPDATE_HAZARD_LAYER,
    payload: layer
  }
}

export const TOGGLE_HAZARD_MODAL = "TOGGLE_HAZARD_MODAL";

export const toggleHazardModal = show => {
  return {
    type: TOGGLE_HAZARD_MODAL,
    payload: show
  }
}

export const RESET_STATE = "RESET_STATE";
export const resetState = () => {
  return {
    type: RESET_STATE,
  }
}

// ログイン情報以外をリセットする。
// このシステムでのログイン情報とは、単に開催場所を意味するので基本的にリセット不要。
export const RESET_USER_INPUT = "RESET_USER_INPUT";
export const resetUserInput = () => {
  return {
    type: RESET_USER_INPUT,
  }
}

export const SET_MAP = "SET_MAP"
export const setMap = obj => {
  return {
    type: SET_MAP,
    payload: obj
  }
}

export const SET_USER_STATE = "SET_USER_STATE";
export const setUserState = userDic => {
    return {
        type: SET_USER_STATE,
        payload: userDic
    }
}

export const UPDATE_MAP_CONTROL = "UPDATE_MAP_CONTROL";
export const updateMapControl = obj => {
  return {
    type: UPDATE_MAP_CONTROL,
    payload: obj
  }
}

export const UPDATE_MARKER = "UPDATE_MARKER";
export const updateMarker = (category, method, subject, idx) => {
  return {
    type: UPDATE_MARKER,
    payload: {
      category: category, // マーカのカテゴリ
      method: method, // "push", "pull", "edit", "delete" いずれか
      subject: subject, // 操作対象
      idx: idx, // マーカのインデックス
    }
  }
}

export const TOGGLE_SHELTER = "TOGGLE_SHELTER";
export const toggleShelter = () => {
  return {
    type: TOGGLE_SHELTER,
  }
}

export const SET_LOGIN_STATE = "SET_LOGIN_STATE";
export const setLoginState = isLogin => {
    return {
        type: SET_LOGIN_STATE,
        payload: isLogin
    }
}