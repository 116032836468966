import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Confetti from "../assets/js/kamifubuki"

import * as $ from 'jquery'

import {resetUserInput} from "../store/actions";
import Axios from "axios";
import _env from '../config/env'
import QRCode from "qrcode.react"
import { Loader } from '../components';

const EndSection = props => {
  const loginState = useSelector(state => state.loginState);
  const [response, setResponse] = useState({
    user_id: "none",
    password: "0000",
    url: null
  })
  const [animationObj, setAnimationObj] = useState(null)

  const userinfoState = useSelector(state => state.userinfoState);
  const mapControlState = useSelector(state => state.mapControlState);
  const hazardLayerState = useSelector(state => state.hazardLayerState);
  const markers = useSelector(state => state.markers);
  const dispatch = useDispatch();

  const jumpTo = pageName => {
    delete require.cache[
      require.resolve("../assets/js/loading")
    ];
    stop();
    props.history.push(pageName);
    return
  }

  const start = () => {
    const DURATION = 13000,
      LENGTH = 100;

    const tmpAnimationObj = new Confetti({
      width: window.innerWidth,
      height: window.innerHeight,
      length: LENGTH,
      duration: DURATION
    });
    setAnimationObj(tmpAnimationObj)
    return
  }

  const stop = () => {
    if (animationObj !== null) {
      animationObj.stop();
    }
    $("canvas").remove();
    return
  }

  const onAgainClick = () => {
    delete require.cache[require.resolve("../assets/js/loading")];
    dispatch(resetUserInput());
    jumpTo("/");
    return
  };

  const onBack = () => {
    delete require.cache[require.resolve("../assets/js/loading")];
    props.history.goBack();
    return
  };

  // this will cause render() to be called twice, less efficient than only calling it once,
  // other than that this is fine
  useEffect(() => {
    console.log('USE EFECT')
    let reqObj = {}
    let userInfoObj = {};
    let mapInfoObj = {};

    // ユーザID
    reqObj["user_id"] = userinfoState.userId
    // まとめ情報
    reqObj["json_info"] = {}
    // ユーザ情報
    userInfoObj = userinfoState.infolist
    reqObj["json_info"]["user_info"] = userInfoObj
    // マップ情報
    mapInfoObj["zoom"] = mapControlState.zoom
    mapInfoObj["center"] = mapControlState.center
    mapInfoObj["home"] = markers.home
    mapInfoObj["relatives"] = markers.relatives
    mapInfoObj["shelter"] = markers.shelterVisible
    mapInfoObj["hazard"] = hazardLayerState.hazardLayer
    mapInfoObj["toilet"] = markers.toilet
    mapInfoObj["water"] = markers.water
    mapInfoObj["rest_area"] = markers.lounge
    mapInfoObj["hospital"] = markers.hospital
    mapInfoObj["car"] = markers.car
    reqObj["json_info"]["map_info"] = mapInfoObj

    Axios.post(
      `${_env.fastAPI}/post_map_info`, 
      {
        user_id: reqObj["user_id"],
        json_info: reqObj["json_info"],
      },{
        headers: {
          Authorization: `Bearer ${loginState.userInfo.token}`,
        }
      }).then((response) => {
      console.log(response)
      setResponse(response.data)
    }).catch((error) => {
      console.log(error)
    })
    start();
    if (markers.home === null) {
      stop();
    }
  }, [])

  if (markers.home === null) {
    jumpTo("/");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-container is-map">
          <div className="l-contents">
            <div className="p-result">
              <h1 className="p-result-ttl">
                <span className="p-user-name">{userinfoState.infolist.name}</span>
                <span className="p-user-gender">
                  {userinfoState.infolist.sex === "man"
                    ? "くん"
                    : "ちゃん"
                  }
                </span>
                {userinfoState.infolist.age >= 9
                  ? "のマップが完成!!"
                  : "のマップができあがり!!"
                }
              </h1>
              <p className="p-hosoku2">
                スマホでQRコードにアクセス！パスワードを忘れないでね。
              </p>
              <p className="p-qr">
                {/* <img src={QRIcon} alt="" /> */}
                {response.url !== null && <QRCode value={response.url} />}
              </p>
              <p className="p-password">
                <span className="p-password__txt">パスワード：</span>
                <span className="p-password__number">{response.password}</span>
              </p>
              <ul className="p-btn-list">
                <li>
                  <button
                    onClick={() => onAgainClick()}
                    className="c-btn c-btn-default c-btn-lg"
                  >
                    おわり
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => onBack()}
                    className="c-btn-back"
                  >
                    <span>もどる</span>
                  </button>
                </li>
              </ul>

            </div>
          </div>
        </div>
        <div className="p-bg-boy">
          <span />
        </div>
      </div>
    );
  }
}

export default EndSection;
