import React from "react";
import GasIcon from "../assets/img/icon_car.png";
import { useSelector, useDispatch } from "react-redux";
import { CombinedMap, Loader, StepBar } from "../components";
import { updateMarker } from "../store/actions";

const CarSection = props => {
  const userinfoState = useSelector(state => state.userinfoState);
  const markers = useSelector(state => state.markers);
  const mapState = useSelector(state => state.mapState);
  const dispatch = useDispatch();
  const onAddMarker = () => {
    dispatch(updateMarker("car", "push", mapState.mapObj.current.leafletElement.getCenter(), null));
    return
  } // マーカ追加時
  const jumpTo = pageName => {
    delete require.cache[
      require.resolve("../assets/js/loading")
    ];
    props.history.push(pageName);
    return
  }
  if (markers.home === null) {
    jumpTo("/");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-wrap">
          <main className="l-main">
            <CombinedMap className="p-gmap" editType="car" />
          </main>
          <div className="l-side">
            <div className="l-side-inner">
              <section className="p-theme">
                <p className="p-theme-txt">
                  {userinfoState.infolist.age >= 9
                    ? "車はどこに駐車しておくと安全かな？車マークを駐車場所まで動かしてみよう。"
                    : "くるまはどこにとめておくとあんぜんかな？くるまマークをとめるばしょまでうごかしてみよう"
                  }
                </p>
              </section>
              <section
                className="p-icon-area"
                onClick={() => onAddMarker()}
              >
                <button>
                  <img src={GasIcon} alt="" />
                </button>
              </section>
              <ul className="p-btn-list">
                <li>
                  <button
                    className="c-btn-back"
                    onClick={() => jumpTo("/hospital")}
                  >
                    <span>もどる</span>
                  </button>
                </li>
              </ul>
              <section className="p-btn-end">
                <ul className="p-btn-list">
                  <li>
                    <button
                      onClick={() => jumpTo("/confirm")}
                      className="c-btn c-btn-red c-btn-xl"
                    >
                      完成確認
                    </button>
                  </li>
                </ul>
              </section>
            </div>
          </div>
          <StepBar step="car" history={props.history} />
        </div>
      </div>
    );
  }
}

export default CarSection;
