import React from 'react';
import _env from '../config/env';

const HanreiList = props => {
    return (
        <div className="l-hanrei-icon">
            <ul className="p-hanrei-list">
                {_env.hanreiList.map(hanrei => {
                    return (
                        <li key={hanrei.name}>
                            <dl className="p-hanrei-set">
                                <dt className="p-hanrei-icons"><img src={hanrei.src} alt="" /></dt>
                                <dd className="p-hanrei-txt">{hanrei.name}</dd>
                            </dl>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

export default HanreiList;