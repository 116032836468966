import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import env from '../config/env';
import Titlelogo from "../assets/img/logo.png";
import { setUserState, setLoginState } from '../store/actions';
import { Loader } from '../components';
import { useCookies } from 'react-cookie';

const LoginSection = props => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const loginState = useSelector(state => state.loginState);
  const [cookies, setCookie, removeCookie] = useCookies(["state"]);
  const execLogin = () => {
    if (email !== "" && password !== ""){
      const response = login(email, password)
        .then(res => {
          return res;
        }).catch(err => {
          return "error";
        });
      
      response.then(e => {
        
        if (e === "failed" || e === "error") {
          
          console.log("ログイン失敗 エラー:"+e)
          
          setPassword("");
        } else {
          
          // TODO このタイミングではloginStateの更新を取得できていなかったのでコメントアウト
          // setCookie("state", loginState);

          props.history.push("/");
        }

      }).catch(err => {

        console.log("ログイン失敗 システムエラー:"+err)
        
        setPassword("");
      })

    } else {
      window.alert("入力できていない項目があるよ")
    }
  };

  const login = async (email, password) => {
    const response = await axios.post(`${env.loginUrl}`,
        {
          grant_type: "password",
          username: email,
          password: password,
          client_id: `${env.CLIENT_ID}`,
          client_secret: `${env.CLIENT_SECRET}`,
          scope: "",
        }
    ).then(res => {
        if ((res === undefined || res.status !== 200) || res === null) {
          return "failed";
        }
        return res.data;
    }).catch(err => {
      
      // 偽のレスポンスを渡している
      // return {email: "xxxxx", access_token: "XXXXXXXXXX"};  

      return "error";
    });
  
    if(response === "failed"){
      window.alert("ログインに失敗しました。もう一度やり直してください。");
      return "error";
    } else if(response === "error"){
      window.alert("ログイン中にエラーが発生しました。もう一度やり直してください。");
      return "error";
    } else {
      
      // dispatchとの同期タイミングが分からなかったので暫定的にすぐにcookieを更新
      setCookie("state", {
        isLogin: true,
        userInfo: {
          email: response.email,
          token: response.access_token
        }
      });
      
      dispatch(setUserState({
        email: response.email, 
        token: response.access_token
      }));
      
      dispatch(setLoginState(true));
    }
  
    return response;
  };

  require('../assets/js/loading');
  return (
    <div>
      <Loader />
      <div className="l-container is-map">
        <div className="l-contents">
          <h1 className="p-logo">
            <img src={Titlelogo} alt="マイマップ" />
          </h1>
          {/* <dl className="p-dl-list is-form is-first"> */}
            {/* <form>
              <div className="top-login"> */}
                <dl className="p-dl-list is-form is-first">
                  <dt>メールアドレス</dt>
                  <dd>
                    <input type="text" placeholder="" value={email} onChange={e => setEmail(e.target.value)} className="f-w-lg" />
                  </dd>
                </dl>
                <dl className="p-dl-list is-form">
                  <dt>パスワード</dt>
                  <dd>
                    <input type="text" placeholder="" value={password} onChange={e => setPassword(e.target.value)} className="f-w-lg" />
                  </dd>
                </dl>

                <ul className="p-submit">
                  <button className="c-btn c-btn-default c-btn-lg" onClick={() => execLogin()}>ログイン</button>
                </ul>
              {/* </div>
            </form> */}
          {/* </dl> */}
        </div>
      </div>

      {/* フッター */}
      <div className="p-footer-stage">
        <div className="p-footer-wrap" data-order="left">
          <span className="p-footer-item p-footer-anime" />
        </div>
      </div>
      {/* フッター */}
    </div>
  )
};
  
export default LoginSection;