import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Axios from "axios";
import _env from "../config/env";
import Titlelogo from "../assets/img/logo.png";

import {
  updateUserInfo,
  updateMapControl,
  updateHazardLayer,
  updateMarker,
  toggleShelter
} from "../store/actions";

import { Loader } from '../components';


const PasswordSection = props => {
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const fetchMarker = async (organizationId) => {
    await Axios.get(`${_env.fastAPI}/p_markers`,
      {
        params: {
          organizationId: organizationId
        },
        headers: {
          // Authorization: `Bearer ${loginState.userInfo.token}`,
        }
      }
    ).then(res => {
      dispatch(updateMarker("shelter", "replace", res.data, null));
    });
    return
  };
  const fetchDistributionMarker = async (organizationId) => {
    await Axios.get(`${_env.fastAPI}/p_distribution_markers`,    
      {
        params: {
          organizationId: organizationId
        },
        headers: {
          // Authorization: `Bearer ${loginState.userInfo.token}`,
        }
    }).then(res => {
      dispatch(updateMarker("shop", "replace", res.data, null));
    });
    return
  };
  const submitPassword = () => {
    // URLパラメータ取得
    let urlParamStr = window.location.search;
    if (urlParamStr) {
      //?を除去
      urlParamStr = urlParamStr.substring(1);

      let params = {};
      //urlパラメータをオブジェクトにまとめる
      urlParamStr.split("&").forEach(param => {
        const temp = param.split("=");
        //pramsオブジェクトにパラメータを追加
        params = {
          ...params,
          [temp[0]]: temp[1]
        };
      });

      // user_idとpasswordをPOST
      Axios.post(`${_env.fastAPI}/get_map_info`, {
        user_id: params["user_id"],
        password: password
      })
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            let resData = response.data;
            Promise.all([
              dispatch(updateUserInfo(resData.map_info.user_info)),
              dispatch(updateMapControl({ zoom: resData.map_info.map_info.zoom, center: resData.map_info.map_info.center })),
              dispatch(updateHazardLayer(resData.map_info.map_info.hazard)),
              dispatch(updateMarker("home", "replace", resData.map_info.map_info.home, null)),
              dispatch(updateMarker("relatives", "replace", resData.map_info.map_info.relatives, null)),
              dispatch(updateMarker("toilet", "replace", resData.map_info.map_info.toilet, null)),
              dispatch(updateMarker("water", "replace", resData.map_info.map_info.water, null)),
              dispatch(updateMarker("lounge", "replace", resData.map_info.map_info.rest_area, null)),
              dispatch(updateMarker("hospital", "replace", resData.map_info.map_info.hospital, null)),
              dispatch(updateMarker("car", "replace", resData.map_info.map_info.car, null)),
              fetchMarker(resData.organization_id),
              fetchDistributionMarker(resData.organization_id),
            ]).then(() => {
              if (resData.map_info.map_info.shelter === true) {
                dispatch(toggleShelter())
              }
              props.history.push("/print")
            })
            // ここでデータを格納＆printページに遷移する処理を記述する
          } else {
            //DBに該当するデータがない，パスワードが間違っているなどの場合
            console.log("could not get information", response);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      console.log("URLパラメータを取得できませんでした．");
    }
    return
  };

  require("../assets/js/loading");
  const urlParamStr = window.location.search;
  return (
    <div>
      <Loader />
      <div className="l-container is-map">
        <div className="l-contents">
          <h1 className="p-logo">
            <img src={Titlelogo} alt="マイマップ" />
          </h1>
          {urlParamStr !== "" ? <div className="p-result">
            <h1 className="p-password-ttl">4桁のパスワードを入力してね!</h1>
            <form>
              <dl className="f-password">
                <dt>
                  <input
                    type="password"
                    maxLength="4"
                    placeholder=""
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    inputMode="numeric"
                  />
                  {/* <span className="f-error">パスワードが違います</span> */}
                </dt>
              </dl>
            </form>
            <ul className="p-btn-list">
              <li>
                <button
                  onClick={() => submitPassword()}
                  className="c-btn c-btn-default c-btn-lg"
                >
                  マップを見る
                </button>
              </li>
            </ul>
          </div> : <div className="p-result"><h1 className="p-password-ttl">ユーザーの指定が不正です</h1></div>}
        </div>
      </div>

      <div className="p-footer-stage">
        <div className="p-footer-wrap" data-order="left">
          <span className="p-footer-item p-footer-anime"></span>
        </div>
      </div>
    </div>
  );
}

// Enterキー無効化
// TODO depricatedの対応
document.onkeypress = function(e) {
  if (e.key === 'Enter') {
    return false;
  }
};

export default PasswordSection;
