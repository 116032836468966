import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { CombinedMap, Loader, StepBar, HazardModal, HazardHanrei, HazardLabel} from "../components";
import {toggleHazardModal} from "../store/actions";

const HazardSection = props => {
  const markers = useSelector(state => state.markers);
  const userinfoState = useSelector(state => state.userinfoState);
  const hazardLayerState = useSelector(state => state.hazardLayerState);

  const dispatch = useDispatch();

  const showModal = (show) => {
    dispatch(toggleHazardModal(show));
  }
  
  const jumpTo = pageName => {
    delete require.cache[
      require.resolve("../assets/js/loading")
    ];
    props.history.push(pageName);
    return
  }
  if (markers.home === null) {
    jumpTo("/");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-wrap">
          <main className="l-main">
            <CombinedMap className="p-gmap" editType="hazard" />
          </main>
          <div className="l-side">
            <div className="l-side-inner">
              <section className="p-theme">
                <p className="p-theme-txt">
                  {userinfoState.infolist.age >= 9
                    ? "ハザードマップを重ねてみるよ。ハザードのボタンを押して危険な場所を確かめよう！"
                    : "ハザードマップをかさねてみるよ。ハザードのボタンをおしてきけんなばしょをたしかめよう！"
                  }
                </p>
              </section>

              <section className="u-pb-xs">
                <button className="c-btn c-btn-green c-btn-xl" onClick={() => showModal(true)}>ハザード</button>
              </section>
              
              <ul className="p-btn-list">
                <li>
                  <button
                    className="c-btn c-btn-yellow c-btn-xl"
                    onClick={() => jumpTo("/toilet")}
                  >
                    つぎへ
                  </button>
                </li>
                <li>
                  <button
                    className="c-btn-back"
                    onClick={() => jumpTo("/shelter")}
                  >
                    <span>もどる</span>
                  </button>
                </li>
              </ul>
              <section className="p-btn-end">
                <ul className="p-btn-list">
                  <li>
                    <button
                      onClick={() => jumpTo("/confirm")}
                      className="c-btn c-btn-red c-btn-xl"
                    >
                      完成確認
                    </button>
                  </li>
                </ul>
              </section>
            </div>
          </div>

          <HazardHanrei />
          <StepBar step="hazard" history={props.history} />
        </div>

        <HazardLabel />

        {/* ハザードのモーダル */}
        {
          hazardLayerState.showSelectModal && <HazardModal />
        }
        
      </div>
    );
  }
}

export default HazardSection;
