/* eslint-disable import/first */
import React from "react";
import {
  Provider
} from "react-redux";
import {
  createStore,
  applyMiddleware
} from "redux";
import Leaflet from "leaflet";
import {
  polyfill
} from "mobile-drag-drop";
polyfill();
// css
import "./App.css";
import "leaflet/dist/leaflet.css";
// コンポーネント
import {
  AppContainer
} from './components'
// 結合済みのレデューサ
import combinedReducer from "./store/combinedReducer";
import { CookiesProvider } from "react-cookie";

// create store with reducer and middleware
const store = createStore(
  combinedReducer,
  applyMiddleware()
);

// Leafletのデフォアイコン設定
Leaflet.Icon.Default.imagePath =
  "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/";

const App = props => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </CookiesProvider>
  );
}

export default App;
