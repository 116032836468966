import {UPDATE_MARKER, TOGGLE_SHELTER} from '../actions';

const initialState = {
    home: null, // 自分の家
    relatives: [], // 親戚の家等
    toilet: [], // トイレ
    water: [], // 水飲み場
    lounge: [], // 休憩所
    hospital: [], // 病院
    car: [], // 車
    shelter: [], // 避難所
    shelterVisible: false, // 避難所の可視性 その他でvisibleを使うなら別でreducer作る
    shop: [], // トヨタの店舗
    school: [] // 学校
};

const markers = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_MARKER: // マーカの更新
            const {category, method, subject, idx} = action.payload;
            switch(method){
                case "replace": // APIからのマーカデータ
                    return {
                        ...state,
                        [category]: subject // そのまま適用
                    }
                case "push": // 新規マーカ
                    return {
                        ...state,
                        [category]: state[category].concat(subject) // 結合
                    }
                case "edit": // 編集したマーカの置換
                    let editable = [...state[category]];
                    editable[idx] = subject;
                    return {
                        ...state,
                        [category]: editable
                    }
                case "remove": // マーカの削除
                    let removable = [...state[category]];
                    removable.splice(idx, 1);
                    return {
                        ...state,
                        [category]: removable
                    }
                default:
                    return state
            }
        case TOGGLE_SHELTER:
            return {
                ...state,
                shelterVisible: !state.shelterVisible
            }
        default:
            return state;
    }
}

export default markers;