import {UPDATE_USER_INFO, UPDATE_USER_ID} from '../actions';

const initialState = {
  infolist:{
    name:`a`,
    age:1,
    sex:`b`,
    hasFam:2,
  },
  userId: ""
};

const userinfoState = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO:
      let newuserState = {...state};
      newuserState.infolist = action.payload;
      return newuserState;
    case UPDATE_USER_ID:
      let newState = {...state};
      newState.userId = action.payload;
      return newState
    default:
      return state;
  }
};

export default userinfoState;
