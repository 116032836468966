// [コンポーネント]
//   ステップバー
// [用法]
//   <StepBar step="[現在のステップ名]"/>
// [概要]
//   ・画面上部のステップバー
//   ・現在のステップ数までのclassをis-activeにする
//   ・ステップをクリックで画面遷移
//   ・ホームマーカの設置済みでないと画面遷移不可
// [TODO] 完了次第削除
//   react-routerのwithRouterでhistoryを渡すべきだが、エラー発生
//   現状親要素からpropでhistoryを渡しているので修正
import React from 'react';
import {
    useSelector
} from 'react-redux';
import {
    withRouter
} from 'react-router';
import _env from '../config/env';

const StepBar = props => {
    const markers = useSelector(state => state.markers);
    const isActive = step => {
        const currentStep = {
            "home": 1,
            "relatives": 2,
            "shelter": 3,
            "hazard": 4,
            "toilet": 5,
            "water": 6,
            "lounge": 7,
            "hospital": 8,
            "car": 9
        }[props.step];
        if (step <= currentStep) {
            return "is-active";
        } else {
            return "";
        }
    }
    const jumpTo = pageName => {
        // ホームマーカの設置完了チェック後に画面遷移
        if (markers.home !== null) {
            delete require.cache[
                require.resolve("../assets/js/loading")
            ];
            props.history.push(pageName);
            return
        } else {
            window.alert("ホームマーカの設置が終了していません");
            return
        }
    }
    return (
        <div className="l-stepbar">
            <ul className="p-stepbar">
                {
                    _env.stepBarList.map(step => {
                        return (
                            <li className={isActive(step.step)} key={step.step.toString()}>
                                <button onClick={() => jumpTo(step.route)}>
                                    <span className={`p-step-icon ${step.class}`}></span>
                                </button>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}

export default StepBar;