import React from 'react';
import _env from '../config/env';
import {useSelector} from "react-redux";

const HazardHanrei = props => {
  const hazardLayerState = useSelector(state => state.hazardLayerState);
  
  return (
      <div className={ props.print ? "l-hanrei l-hanrei--print" : "l-hanrei" }>
        {hazardLayerState.hazardLayer === "kouzui" &&
          <ul className="p-hanrei p-hanrei--kouzui">
            <li>5〜</li>
            <li>2〜5</li>
            <li>1〜2</li>
            <li>0.5〜1</li>
            <li>0〜</li>
            <li>m</li>
          </ul>
        }
        {hazardLayerState.hazardLayer === "takashio" &&
          <ul className="p-hanrei p-hanrei--tsunami">
            <li>20〜</li>
            <li>10〜20</li>
            <li>5〜10</li>
            <li>3〜5</li>
            <li>1〜3</li>
            <li>0.5〜1</li>
            <li>0.3〜0.5</li>
            <li>0〜</li>
            <li>m</li>
          </ul>
        }
        {hazardLayerState.hazardLayer === "tsunami" &&
          <ul className="p-hanrei p-hanrei--tsunami">
            <li>20〜</li>
            <li>10〜20</li>
            <li>5〜10</li>
            <li>3〜5</li>
            <li>1〜3</li>
            <li>0.5〜1</li>
            <li>0.3〜0.5</li>
            <li>0〜</li>
            <li>m</li>
          </ul>
        }
        {hazardLayerState.hazardLayer === "dosekiryu" &&
          <ul className="p-hanrei p-hanrei--dosekiryu">
            <li>特別警戒区域</li>
            <li>警戒区域</li>
          </ul>
        }
        {hazardLayerState.hazardLayer === "kyukeisha" &&
          <ul className="p-hanrei p-hanrei--kyuukeishachi">
            <li>特別警戒区域</li>
            <li>警戒区域</li>
          </ul>
        }
        {hazardLayerState.hazardLayer === "jisuberi" &&
          <ul className="p-hanrei p-hanrei--kyuukeishachi">
            <li>特別警戒区域</li>
            <li>警戒区域</li>
          </ul>
        }
      </div>
    );
}

export default HazardHanrei;