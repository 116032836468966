import {
  UPDATE_HAZARD_VISIBLE,
  UPDATE_HAZARD_LAYER,
  TOGGLE_HAZARD_MODAL
} from "../actions";

const initialState = {
  hazardLayerVisible: false,
  hazardLayer: "nothing",
  showSelectModal: false,
};

const hazardLayerState = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HAZARD_VISIBLE:
      let nextState = { ...state };
      nextState.hazardLayerVisible = !nextState.hazardLayerVisible
      return nextState;
    case UPDATE_HAZARD_LAYER:
      let newState = {...state};
      newState.hazardLayer = action.payload
      return newState;
    case TOGGLE_HAZARD_MODAL:
      let toggleState = {...state};
      toggleState.showSelectModal = action.payload
      return toggleState;
    default:
      return state;
  }
};

export default hazardLayerState;
