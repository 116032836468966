import SpotImgHome from "../assets/img/icon_home_sp.png";
import SpotImgFree from "../assets/img/icon_other_sp.png";
import SpotImgCar from "../assets/img/icon_car_sp.png";
import SpotImgToilet from "../assets/img/icon_toilet_sp.png";
import SpotImgWater from "../assets/img/icon_water_sp.png";
import SpotImgRest from "../assets/img/icon_yane_sp.png";
import SpotImgHospital from "../assets/img/icon_hospital_sp.png";
import SpotImgEscape from "../assets/img/icon_hinanjo_sp.png";
import relativesIcon from "../assets/img/icon_other.png";
import toiletIcon from "../assets/img/icon_toilet.png";
import waterIcon from "../assets/img/icon_water.png";
import loungeIcon from "../assets/img/icon_yane.png";
import hospitalIcon from "../assets/img/icon_hospital.png";
import carIcon from "../assets/img/icon_car.png";

const googleApiKey = "AIzaSyC6O-Hy6Ap8FHC7J-KPbHn8X0_h2jcO_kg";

const fastAPI = "https://mapclub-api2.data-kids.com/api";
const loginUrl ='https://mapclub-api2.data-kids.com/oauth/token'

const CLIENT_ID ='4'
const CLIENT_SECRET ='rmenBHlYwuvH3yxPLJ7nEfSxadPhVAVseuKnMiCx'

const publishMode = "publish" // 全てのページにアクセス可能
// const publishMode = "afterglow" // パスワード画面と印刷画面のみアクセス可能
const stepBarList = [
    {
        class: "p-icon-house",
        route: "/home",
        step: 1
    },
    {
        class: "p-icon-other",
        route: "/relatives",
        step: 2
    },
    {
        class: "p-icon-hinanjo",
        route: "/shelter",
        step: 3
    },
    {
        class: "p-icon-hazard",
        route: "/hazard",
        step: 4
    },
    {
        class: "p-icon-toilet",
        route: "/toilet",
        step: 5
    },
    {
        class: "p-icon-water",
        route: "/water",
        step: 6
    },
    {
        class: "p-icon-yane",
        route: "/lounge",
        step: 7
    },
    {
        class: "p-icon-hospital",
        route: "/hospital",
        step: 8
    },
    {
        class: "p-icon-car",
        route: "/car",
        step: 9
    },
]; // ステップバーのリスト
const hanreiList = [
    {
        src: SpotImgHome,
        name: "自分の家"
    },
    {
        src: SpotImgFree,
        name: "任意の場所"
    },
    {
        src: SpotImgCar,
        name: "自家用車"
    },
    {
        src: SpotImgToilet,
        name: "トイレ"
    },
    {
        src: SpotImgWater,
        name: "給水"
    },
    {
        src: SpotImgRest,
        name: "休憩場所"
    },
    {
        src: SpotImgHospital,
        name: "病院"
    },
    {
        src: SpotImgEscape,
        name: "避難場所"
    },
] // 凡例のリスト
const markerList = [
    {
        category: "relatives",
        iconUrl: relativesIcon,
        iconAnchor: [25, 50],
        iconSize: [50, 50],
        popupAnchor: [0, -50]
    },
    {
        category: "toilet",
        iconUrl: toiletIcon,
        iconAnchor: [25, 50],
        iconSize: [50, 50],
        popupAnchor: [0, -50]
    },
    {
        category: "water",
        iconUrl: waterIcon,
        iconAnchor: [25, 50],
        iconSize: [50, 50],
        popupAnchor: [0, -50]
    },
    {
        category: "lounge",
        iconUrl: loungeIcon,
        iconAnchor: [25, 50],
        iconSize: [50, 50],
        popupAnchor: [0, -50]
    },
    {
        category: "hospital",
        iconUrl: hospitalIcon,
        iconAnchor: [25, 50],
        iconSize: [50, 50],
        popupAnchor: [0, -50]
    },
    {
        category: "car",
        iconUrl: carIcon,
        iconAnchor: [25, 50],
        iconSize: [50, 50],
        popupAnchor: [0, -50]
    },
]; // 親戚~車までのマーカのリスト CombinedMapで使用
export default {
    googleApiKey,
    fastAPI,
    publishMode,
    stepBarList,
    hanreiList,
    markerList,
    loginUrl,
    CLIENT_ID,
    CLIENT_SECRET
}