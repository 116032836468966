import {UPDATE_MAP_CONTROL} from '../actions'
import { UPDATE_MAP_CENTER } from "../actions";

const initialState = {
    zoom: 15,
    center: { lat: 34.6082436, lng: 133.8714699 }
  };
  
  const mapControlState = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_MAP_CONTROL:
        let newState = { ...state };
        newState.zoom = action.payload.zoom;
        newState.center = action.payload.center;
        return newState;
      case UPDATE_MAP_CENTER:
        return {
          ...state,
          center: action.payload
        };
      default:
        return state;
    }
  };
  
  export default mapControlState;
  