import React from "react";
import HospitalIcon from "../assets/img/icon_hospital.png";
import { useSelector, useDispatch } from "react-redux";
import {
  CombinedMap, Loader, StepBar
} from "../components";
import { updateMarker } from "../store/actions";

const HospitalSection = props => {
  const markers = useSelector(state => state.markers);
  const userinfoState = useSelector(state => state.userinfoState);
  const mapState = useSelector(state => state.mapState);
  const dispatch = useDispatch();
  const onAddMarker = () => {
    dispatch(updateMarker("hospital", "push", mapState.mapObj.current.leafletElement.getCenter(), null));
    return
  } // マーカ追加時
  const jumpTo = pageName => {
    delete require.cache[
      require.resolve("../assets/js/loading")
    ];
    props.history.push(pageName);
    return
  }
  if (markers.home === null) {
    jumpTo("/");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-wrap">
          <main className="l-main">
            <CombinedMap
              className="p-gmap"
              editType="hospital"
            />
          </main>
          <div className="l-side">
            <div className="l-side-inner">
              <section className="p-theme">
                <p className="p-theme-txt">
                  {userinfoState.infolist.age >= 9
                    ? "ケガをしたとき、近くに病院はあるかな？"
                    : "ケガをしたとき、ちかくにびょういんはあるかな？"
                  }
                </p>
              </section>
              <section
                className="p-icon-area"
                onClick={() => onAddMarker()}
              >
                <button>
                  <img src={HospitalIcon} alt="" />
                </button>
              </section>
              <ul className="p-btn-list">
                <li>
                  <button
                    className="c-btn c-btn-yellow c-btn-xl"
                    onClick={() => jumpTo("/car")}
                  >
                    つぎへ
                </button>
                  <button
                    className="c-btn-back"
                    onClick={() => jumpTo("/lounge")}
                  >
                    <span>もどる</span>
                  </button>
                </li>
              </ul>
              <section className="p-btn-end">
                <ul className="p-btn-list">
                  <li>
                    <button
                      onClick={() => jumpTo("/confirm")}
                      className="c-btn c-btn-red c-btn-xl"
                    >
                      完成確認
                    </button>
                  </li>
                </ul>
              </section>
            </div>
          </div>
          <StepBar step="hospital" history={props.history} />
        </div>
      </div>
    );
  }
}

export default HospitalSection;

