import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import {CombinedMap, Loader, HanreiList, HazardHanrei, HazardLabel} from "../components";
import IconFormBoy from "../assets/img/icon_form_boy_sp.png";
import IconFormGirl from "../assets/img/icon_form_girl_sp.png";

const ConfirmSection = props => {
  const markers = useSelector(state => state.markers);
  const userinfoState = useSelector(state => state.userinfoState);
  
  useEffect(() => {

  }, [])

  
  const jumpTo = pageName => {
    delete require.cache[
      require.resolve("../assets/js/loading")
    ];
    props.history.push(pageName);
    return
  }
  const goBack = () => {
    delete require.cache[
      require.resolve("../assets/js/loading")
    ];
    props.history.goBack();
    return
  }
  if (markers.home === null) {
    jumpTo("/");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-wrap">
          <main className="l-main">
            <CombinedMap className="p-gmap" editType="confirm" />
          </main>
          <div className="l-name">
            <div className="l-name-inner">
              <ul className="p-print-head is-confirm">
                <li>
                  <div className="p-print-head__in">
                    <span className="p-user-icon">
                      <img
                        src={
                          userinfoState.infolist.sex === "man"
                            ? IconFormBoy
                            : IconFormGirl
                        }
                        alt=""
                      />
                    </span>
                    <span
                      className={
                        userinfoState.infolist.sex === "man"
                          ? "p-user-name is-boy"
                          : "p-user-name is-girl"
                      }
                    >
                      {userinfoState.infolist.name}
                    </span>
                    <span className="p-user-gender">
                      {userinfoState.infolist.sex === "man"
                        ? "くん"
                        : "ちゃん"}
                    </span>
                    が作ったマップ
                  </div>
                </li>
                <li>
                  <button
                    className="c-btn c-btn-default c-btn-sm u-mr-sm"
                    onClick={() => goBack()}
                  >
                    もどる
                  </button>
                  <button
                    onClick={() => {
                      window.print();
                    }}
                    className="c-btn c-btn-yellow c-btn-sm u-mr-sm"
                  >
                    印刷
                  </button>
                  <button
                    className="c-btn c-btn-red c-btn-sm"
                    onClick={() => jumpTo("/end")}
                  >
                    完成
                  </button>
                </li>
              </ul>
            </div>
          </div>


          {/* 選択したハザード名 */}
          <HazardLabel print={true} />
          
          <HazardHanrei print={true} />
          <HanreiList />
        </div>
      </div>
    );
  }
}

export default ConfirmSection;
