import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CombinedMap, Loader, StepBar } from "../components";
import HinanjoIcon from "../assets/img/icon_hinanjo.png";

import {
  toggleShelter
} from "../store/actions";

const ShelterSection = props => {
  const markers = useSelector(state => state.markers);
  const userinfoState = useSelector(state => state.userinfoState);
  const dispatch = useDispatch();
  const jumpTo = pageName => {
    delete require.cache[require.resolve("../assets/js/loading")];
    props.history.push(pageName);
    return
  };
  if (markers.home === null) {
    jumpTo("/");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-wrap">
          <main className="l-main">
            <CombinedMap className="p-gmap" editType="shelter" />
          </main>
          <div className="l-side">
            <div className="l-side-inner">
              <section className="p-theme">
                <p className="p-theme-txt">
                  {userinfoState.infolist.age >= 9
                    ? "家のちかくのひなんじょはどこかな？避難所のボタンを押して確かめよう！"
                    : "いえのちかくのひなんじょはどこかな？ひなんじょのボタンをおしてたしかめよう！"
                  }
                </p>
              </section>
              <section className="p-btn-xl">
                {/* ボタンを押したら aタグにclass="is-active"を追記 */}
                <button onClick={() => dispatch(toggleShelter())}>
                  <span className="p-btn-xl-icon">
                    <img src={HinanjoIcon} alt="hinanjo_icon" />
                  </span>
                  <span>
                    {userinfoState.infolist.age >= 9
                      ? "避難所"
                      : "ひなんじょ"
                    }
                  </span>
                </button>
              </section>
              <ul className="p-btn-list">
                <li>
                  <button
                    className="c-btn c-btn-yellow c-btn-xl"
                    onClick={() => jumpTo("/hazard")}
                  >
                    つぎへ
                  </button>
                </li>
                <li>
                  <button
                    className="c-btn-back"
                    onClick={() => jumpTo("/relatives")}
                  >
                    <span>もどる</span>
                  </button>
                </li>
              </ul>
              <section className="p-btn-end">
                <ul className="p-btn-list">
                  <li>
                    <button
                      onClick={() => jumpTo("/confirm")}
                      className="c-btn c-btn-red c-btn-xl"
                    >
                      完成確認
                    </button>
                  </li>
                </ul>
              </section>
              <StepBar step="shelter" history={props.history} />
            </div>
          </div>
        </div>
      </div>
    );
  }

}


export default ShelterSection;
