import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CombinedMap, Loader, HanreiList, HazardHanrei, HazardLabel, HazardModal} from "../components";
import IconFormBoy from "../assets/img/icon_form_boy_sp.png";
import IconFormGirl from "../assets/img/icon_form_girl_sp.png";
import {toggleHazardModal} from "../store/actions";

const PrintSection = props => {
  const markers = useSelector(state => state.markers);
  const userinfoState = useSelector(state => state.userinfoState);
  const hazardLayerState = useSelector(state => state.hazardLayerState);

  const dispatch = useDispatch();

  const showModal = (show) => {
    dispatch(toggleHazardModal(show));
  }
  
  
  if (markers.home === null) {
    delete require.cache[require.resolve("../assets/js/loading")];
    props.history.push("/password");
    return null;
  } else {
    require("../assets/js/loading");
    return (
      <div>
        <Loader />
        <div className="l-wrap">
          <main className="l-main">
            <CombinedMap className="p-gmap" editType="print" />
          </main>
          <div className="l-name">
            <div className="l-name-inner">
              <ul className="p-print-head">
                <li>
                  <div className="p-print-head__in">
                    <span className="p-user-icon"><img src={userinfoState.infolist.sex === "man" ? IconFormBoy : IconFormGirl} /></span>
                    <span className={userinfoState.infolist.sex === "man" ? "p-user-name is-boy" : "p-user-name is-girl"}>{userinfoState.infolist.name}</span>
                    <span className="p-user-gender">{
                      userinfoState.infolist.sex === "man" ?
                        "くん" : "ちゃん"}
                    </span>が作ったマップ
                  </div>
                </li>
                <li>
                  <ul className="p-print-head__hazard">
                  <li>
                    <button
                      className="c-btn c-btn-red c-btn-sm"
                      id=""
                      onClick={() => showModal(true)}
                    >ハザードを変更</button>
                  </li>
                  <li>
                    <button
                      className="c-btn c-btn-default c-btn-sm"
                      id="print_btn"
                      onClick={() => window.print("印刷しますか？")}
                    >印刷</button>
                  </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>


          {/* 選択したハザード名 */}
          <HazardLabel print={true} />
          
          <HazardHanrei print={true} />
          <HanreiList />
          
          {/* ハザードのモーダル */}
          {
            hazardLayerState.showSelectModal && <HazardModal />
          }
          
        </div>
      </div>
    );
  }
}

export default PrintSection;
