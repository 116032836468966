import React, {useState} from 'react';
import _env from '../config/env';
import {useSelector} from "react-redux";

const HazardLabel = props => {
  const hazardLayerState = useSelector(state => state.hazardLayerState);

  const [hazardName, setHazardName] = useState("表示しない");

  const getHazardName = () => {
    switch (hazardLayerState.hazardLayer) {
      case "kouzui":
        return "洪水"
        break;
      case "takashio":
        return "高潮"
        break;
      case "tsunami":
        return "津波"
        break;
      case "dosekiryu":
        return "土石流"
        break;
      case "kyukeisha":
        return "急傾斜地"
        break;
      case "jisuberi":
        return "地すべり"
        break;
      case "nothing":
      default:
        return "表示しない"
        break;
    }
  }

  return (
  <div className={ props.print ? "l-hanrei-ttl l-hanrei-ttl--print" : "l-hanrei-ttl" }>
    <h1 className={ props.print ? "p-hanrei-ttl p-hanrei-ttl--print" : "p-hanrei-ttl" }><span className="__sub">ハザード：</span><span className="__main">{ getHazardName() }</span></h1>
  </div>
  )
}

export default HazardLabel;